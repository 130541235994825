<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">{{ event.name }}</h1>
  </div>
</div>

<div class="sky-card mt">
  <div class="sky-card-body scroll-x">
    <app-table [rows]="rows">
      <ng-template [appTableHeader]>#</ng-template>
      <ng-template [appTableHeader]>Name</ng-template>
      <ng-template [appTableHeader]>Year</ng-template>
      <ng-template [appTableHeader]>Start</ng-template>
      <ng-template [appTableHeader]>End</ng-template>
      <ng-template [appTableHeader]>Spirits</ng-template>
      <ng-template [appTableHeader]>IAPs</ng-template>
      <ng-template [appTableHeader]>Unlocked</ng-template>
      <ng-template [appTableColumn] let-row="row">
        {{ row.number }}
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        <a [routerLink]="['/event-instance', row.guid]">{{ row.name }}</a>
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        {{ row.year }}
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        <app-date [date]="row.date"></app-date>
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        <app-date [date]="row.endDate"></app-date>
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        {{ row.spirits }}
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        {{ row.iaps }}
        <ng-container *ngIf="row.returningIaps">
          (<span class="returning" [ngbTooltip]="'Contains ' + row.returningIaps + ' returning IAPs.'" container="body"><mat-icon>undo</mat-icon>{{ row.returningIaps }}</span>)
        </ng-container>
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        <span *ngIf="row.totalItems" [class.completed]="row.unlockedItems === row.totalItems">
          {{ row.unlockedItems }} / {{ row.totalItems }}
        </span>
      </ng-template>
    </app-table>
  </div>
</div>
