<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2">Seasons</h1>
  </div>
  <div class="sky-card-body">
    <div class="grid grid-2">
      <app-wiki-link [wiki]="{href: 'https://sky-children-of-the-light.fandom.com/wiki/Seasonal_Events'}"></app-wiki-link>
      <a class="container" [routerLink]="'/season-calculator'">
        <mat-icon class="menu-icon">calculate</mat-icon>
        <span class="menu-label">Calculator</span>
      </a>
    </div>
    <div class="flex mt">
      <a class="item-icon" *ngFor="let season of reverseSeasons"
        [routerLink]="['/season', season.guid]"
        [ngbTooltip]="season.name" placement="bottom" container="body">
        <img class="season-icon" [src]="season.iconUrl" />
      </a>
    </div>
  </div>
</div>

<ng-container *ngFor="let year of years">
  <div class="sky-card mt">
    <div class="sky-card-header">
      <h2 class="h3">{{year}}</h2>
    </div>

    <div class="sky-card-body">
      <div class="grid grid-2">
        <app-season-card *ngFor="let season of yearMap[year]; let i = index;" [season]="season"></app-season-card>
      </div>
    </div>
  </div>
</ng-container>
