<div class="sky-card p-rel" *ngIf="season">
  <div class="sky-card-header">
    <h2 class="h3 mb-0">
      <img class="season-icon" [src]="season.iconUrl" />
      {{ season.name }}
      <span class="h5 c-accent number">#{{ season.number }}</span>
    </h2>
  </div>
  <div class="sky-card-body grid grid-1">
    <!-- Image -->
    <div *ngIf="sections['img'] && imageUrlSafe"
      class="season-img sky-border-solid" [style.order]="sections['img']"
      [style.background-image]="imageUrlSafe"
      [style.background-position]="season.imagePosition || undefined">
    </div>
    <!-- Overview -->
    <a *ngIf="sections['overview']" [routerLink]="['/season', season.guid]"
      class="item link" [style.order]="sections['overview']">
      <mat-icon class="menu-icon">article</mat-icon>
      <span class="menu-label">Overview</span>
    </a>
    <!-- Spirits -->
    <a *ngIf="sections['spirits']" [routerLink]="'/spirit'" [queryParams]="{ season: season.guid, type: 'Season,Guide' }"
      class="item link" [style.order]="sections['spirits']">
      <mat-icon class="menu-icon">list</mat-icon>
      <span class="menu-label">Spirits <span class="c-accent">({{ season.spirits.length - 1 }})</span></span>
    </a>
    <!-- Date -->
    <div *ngIf="sections['date']"
      class="item" [style.order]="sections['date']">
      <mat-icon class="menu-icon">calendar_month</mat-icon>
      <span class="menu-label">
        <app-date [date]="season.date"></app-date>
        to
        <app-date [date]="season.endDate"></app-date>
        <span class="c-accent">
          (<app-days-left [start]="season.date" [end]="season.endDate"></app-days-left>)
        </span>
      </span>
    </div>
    <!-- Dailies -->
    @if (sections['dailies']) {
      <div [style.order]="sections['dailies']">
        <app-discord-link [link]="'https://discord.com/channels/736912435654688868/801778605486374943'"
          [aStyle]="'text-decoration: none;'" [aClass]="'item link'"
        >
          <img class="menu-icon icon" src="assets/external/discord-mark-white.svg" loading="lazy">
          <span class="menu-label" style="text-decoration: underline;">View dailies</span>&nbsp;<span class="c-accent" style="text-decoration: none;">(opens Sky:CoTL Infographics Database)</span>
        </app-discord-link>
      </div>
    }
    <!-- Check-in -->
    <div *ngIf="sections['checkin']" (click)="checkin($event)"
      class="item checkin" [class.active]="checkedIn" [style.order]="sections['checkin']"
    >
      <mat-icon class="menu-icon">{{checkedIn ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
      <span class="menu-label">
        I have done my dailies.
      </span>
    </div>
    <!-- Calculator -->
    <a *ngIf="sections['calculator']" [routerLink]="['/season-calculator']"
      class="item link" [style.order]="sections['calculator']">
      <mat-icon class="menu-icon">calculate</mat-icon>
      <span class="menu-label">Calculator</span>
    </a>
    <!-- Cost -->
    <div *ngIf="sections['cost'] && cost"
      class="item" [style.order]="sections['cost']"
    >
      <mat-icon class="menu-icon">sell</mat-icon>
      <span class="menu-label">
        <div class="d-inline-block">
          <app-cost [cost]="cost" [remaining]="remainingCost" [borderLeft]="true"></app-cost>
        </div>
      </span>
    </div>
  </div>
</div>
