<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">Tools</h1>
  </div>
</div>

<div class="grid grid-2 mt">
  <!-- Sky Shards -->
  <div class="sky-card">
    <div class="sky-card-header">
      <h1 class="h2 mb-0">Sky Shards</h1>
    </div>
    <div class="sky-card-body">
      <div class="grid grid-1">
        <div class="container d-block">
          A website by Plutoy to view the time and location of shards.
        </div>
        <a class="container d-block" href="https://sky-shards.pages.dev/" target="_blank">
          <mat-icon class="menu-icon">language</mat-icon>
          <span class="menu-label">
            Go to website
          </span>
        </a>
      </div>
    </div>
  </div>
  <!-- Sky Clock -->
  <div class="sky-card">
    <div class="sky-card-header">
      <h1 class="h2 mb-0">Sky Clock</h1>
    </div>
    <div class="sky-card-body">
      <div class="grid grid-1">
        <div class="container d-block">
          A website by Chris Stead to view the time of events such as the geyser in Sky.
        </div>
        <a class="container d-block" href="https://sky-clock.netlify.app/" target="_blank">
          <mat-icon class="menu-icon">schedule</mat-icon>
          <span class="menu-label">
            Go to website
          </span>
        </a>
      </div>
    </div>
  </div>

  <!-- Outfit request -->
  <div class="sky-card">
    <div class="sky-card-header">
      <h1 class="h2 mb-0">Outfit request</h1>
    </div>
    <div class="sky-card-body">
      <div class="grid grid-1">
        <div class="preview-img preview-or"></div>
        <a class="container d-block" [routerLink]="['/outfit-request/closet']">
          <mat-icon class="menu-icon">checkroom</mat-icon>
          <span class="menu-label">
            Sky closet
          </span>
        </a>
        <a class="container d-block" [routerLink]="['/outfit-request/request']">
          <mat-icon class="menu-icon">add</mat-icon>
          <span class="menu-label">
            Create request
          </span>
        </a>
        <a class="container d-block" [routerLink]="['/outfit-request/collage']">
          <mat-icon class="menu-icon">image</mat-icon>
          <span class="menu-label">
            Create collage
          </span>
        </a>
        <a class="container d-block" [routerLink]="['/outfit-request/vault']">
          <mat-icon class="menu-icon">link</mat-icon>
          <span class="menu-label">
            Outfit vault
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
