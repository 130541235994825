<div class="map-container" #mapContainer>
  <button type="button" class="map-control" (click)="scrollToList()">
    <mat-icon>arrow_downward</mat-icon>
  </button>
  <div class="map"></div>
</div>

<div class="">
  <div #table class="sky-card mt">
    <div class="sky-card-header">
      <h2 class="h3 mb-0 d-inline-block">
        <span class="show-desktop">Children of Light</span><span class="c-accent show-desktop"> ({{ unlockedCol }} / {{ totalCol }})</span>
        <div class="show-mobile">{{ unlockedCol }} / {{ totalCol }} obtained</div>
      </h2>
      <div class="btns-reset button-group">
        <button type="button" class="" (click)="unlockAll()">All</button>
        <button type="button" class="btn-reset" (click)="reset()">Reset</button>
        <button type="button" class="btn-reset" (click)="resetOrbit()">Orbit</button>
      </div>
    </div>
    <div class="sky-card-body scroll-x">
      <app-table [rows]="rows">
        <ng-template [appTableHeader] [fit]="true"></ng-template>
        <ng-template [appTableHeader] [width]="'84px'">Found</ng-template>
        <ng-template [appTableHeader]>Area</ng-template>
        <ng-template [appTableHeader]>Realm</ng-template>
        <!-- <ng-template [appTableHeader]>Unlock</ng-template> -->
        <ng-template [appTableColumn] let-row="row">
          <span class="link map-icon" [class.completed]="row.unlocked === row.wl.length">
            <mat-icon (click)="showArea(row)">location_on</mat-icon>
          </span>
        </ng-template>
        <ng-template [appTableColumn] let-row="row">
          <span class="row-unlocked" [class.completed]="row.unlocked === row.wl.length">
            {{ row.unlocked }} / {{ row.wl.length }}
            <mat-icon class="row-toggle" (click)="toggleRow(row)">lock_open</mat-icon>
          </span>
        </ng-template>
        <ng-template [appTableColumn] let-row="row">
          <span [class.completed]="row.unlocked === row.wl.length">{{row.area.name}}</span>
        </ng-template>
        <ng-template [appTableColumn] let-row="row">
          <span [class.completed]="row.unlocked === row.wl.length">{{ row.area.realm.name }}</span>
        </ng-template>
      </app-table>
    </div>
  </div>
</div>
