<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">Permanent Shops</h1>
  </div>
  <div class="sky-card-body">
    <app-wiki-link [wiki]="{href: 'https://sky-children-of-the-light.fandom.com/wiki/Premium_Candle_Shop'}"></app-wiki-link>
  </div>
</div>

<div class="mt">
  <div class="grid grid-2">
    <!-- Aviary Event Store -->
    <div class="sky-card">
      <div class="sky-card-header">
        <h3 class="h4 mb-0">Aviary Event Store</h3>
      </div>
      <div class="sky-card-body">
        <div class="sky-flex flex-wrap">
          <div class="container">
            The Aviary Event Store was introduced in the Season of Revival.<br/>
            The building can be accessed through Aviary Village.
          </div>
          <a class="container d-inline-block" [routerLink]="'/shop/event'">Go to page</a>
        </div>
      </div>
    </div>
    <!-- Nesting Workshop -->
    <div class="sky-card">
      <div class="sky-card-header">
        <h3 class="h4 mb-0">Nesting Workshop</h3>
      </div>
      <div class="sky-card-body">
        <div class="sky-flex flex-wrap">
          <div class="container">
            The Nesting Workshop was introduced in the Season of Nesting.<br/>
            The building can be accessed through Aviary Village.

          </div>
          <a class="container d-inline-block" [routerLink]="'/shop/nesting'">Go to page</a>
        </div>
      </div>
    </div>
    <!-- Concert Hall -->
    <div class="sky-card">
      <div class="sky-card-header">
        <h3 class="h4 mb-0">Concert Hall</h3>
      </div>
      <div class="sky-card-body">
        <div class="sky-flex flex-wrap">
          <div class="container">
            Concert Hall was introduced in the Season of Duets.<br/>
            The hall can be accessed through Aviary Village.
          </div>
          <a class="container d-inline-block" [routerLink]="'/shop/concert-hall'">Go to page</a>
        </div>
      </div>
    </div>
    <!-- Concert Hall -->
    <div class="sky-card">
      <div class="sky-card-header">
        <h3 class="h4 mb-0">Harmony Hall</h3>
      </div>
      <div class="sky-card-body">
        <div class="sky-flex flex-wrap">
          <div class="container">
            Harmony Hall was introduced in the Season of Performance.<br/>
            The building can be accessed through Aviary Village, the Village of Dreams and the Village Theatre.
          </div>
          <a class="container d-inline-block" [routerLink]="'/shop/harmony'">Go to page</a>
        </div>
      </div>
    </div>
    <!-- Office -->
    <div class="sky-card">
      <div class="sky-card-header">
        <h3 class="h4 mb-0">Secret Area</h3>
      </div>
      <div class="sky-card-body">
        <div class="sky-flex flex-wrap">
          <div class="container">
            The Secret Area is only available with a certain cape or during some events.<br/>
            The location can be accessed through the Vault of Knowledge.
          </div>
          <a class="container d-inline-block" [routerLink]="'/shop/office'">Go to page</a>
        </div>
      </div>
  </div>
</div>

<!-- IGC -->
@if (shops.length) {
  <div class="mt">
    <app-card [foldable]="true" [folded]="false" [title]="'Other shops for in-game currency'">
      <div class="sky-flex flex-wrap">
        @for (shop of shops; track shop.guid) {
          <div class="sky-card">
            <div class="sky-card-header">
              <h3 class="h4 mb-0">
                <span>{{ shop.name }}</span>
              </h3>
            </div>
            <div class="sky-card-body">
              <app-item-list [itemList]="shop.itemList!" [highlightNode]="highlightNode"></app-item-list>
            </div>
          </div>
        }
      </div>
    </app-card>
  </div>
}

<!-- IAPs -->
@if (iapShops.length) {
  <div class="mt">
    <app-card [foldable]="true" [folded]="false" [title]="'Other in-app purchases'">
      <div class="grid grid-4">
        @for (shop of iapShops; track shop.guid) {
          @for (iap of shop.iaps; track iap.guid) {
            <app-iap-card [iap]="iap" [highlightIap]="highlightIap"></app-iap-card>
          }
        }
      </div>
    </app-card>
  </div>
}

