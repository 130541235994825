<div class="sky-card">
  <div class="sky-card-body">
    <app-clock></app-clock>
  </div>
</div>

<!-- Search -->
<div class="mt">
  <app-card [title]="'Search'">
    <app-search></app-search>
  </app-card>
</div>

<div class="sky-card mt" *ngIf="!season && !eventInstances.length && !ts">
  <div class="sky-card-body">
    <div class="container">
      There is currently no season or event active.<br/>
      You can view items, seasons, events and more in the menu on the left.
    </div>
  </div>
</div>

<app-dashboard-wishlist [season]="season" [eventInstances]="eventInstances" [ts]="ts" [rs]="rs"></app-dashboard-wishlist>

<div class="grid grid-2 mt">
  <!-- No ongoing events, backup card for dailies -->
  @if (!season && !eventInstances.length) {
    <div class="sky-card">
      <div class="sky-card-header">
        <h1 class="h2 mb-0">Dailies</h1>
      </div>
      <div class="sky-card-body grid grid-1">
        <div class="container">
          <span class="">There are currently no seasons and events active.</span>
        </div>
        <app-discord-link [link]="'https://discord.com/channels/736912435654688868/801778605486374943'"
          [aStyle]="'text-decoration: none;'" [aClass]="'item link'"
        >
          <img class="menu-icon icon" src="assets/external/discord-mark-white.svg" loading="lazy">
          <span class="menu-label" style="text-decoration: underline;">View dailies</span>&nbsp;<span class="c-accent" style="text-decoration: none;">(opens Sky:CoTL Infographics Database)</span>
        </app-discord-link>
        <!-- Check-in -->
        <div (click)="dailyCheckIn()" class="item action" [class.active]="dailyCheckedIn">
          <mat-icon class="menu-icon">{{dailyCheckedIn ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
          <span class="menu-label">
            I have done my dailies.
          </span>
        </div>
      </div>
    </div>
  }
  <!-- Ongoing -->
  <app-season-card *ngIf="season" [season]="season" [options]="{show: ['img', 'overview', 'date', 'dailies', 'checkin', 'calculator', 'cost']}"></app-season-card>
  <ng-container *ngFor="let eventInstance of eventInstances">
    <app-event-card *ngIf="eventInstance" [event]="eventInstance.event" [instance]="eventInstance" [options]="{show: ['img', 'overview', 'date', 'dailies', 'checkin', 'calculator', 'cost']}"></app-event-card>
  </ng-container>
  <app-spirit-card *ngIf="ts" [spirit]="ts.spirit" [ts]="ts" [tree]="ts.tree" [options]="{homeBackground: true, show: ['img', 'ts', 'cost']}">
    <div title><h2 class="h3 mb-0">Traveling Spirit <span class="c-accent h4">(#{{ts.number}})</span></h2></div>
  </app-spirit-card>
  <app-returning-spirit-card *ngIf="rs" [return]="rs"></app-returning-spirit-card>
  <!-- Future -->
  <app-season-card *ngIf="!season && futureSeason" [season]="futureSeason" [options]="{show: ['img', 'overview', 'date', 'cost']}"></app-season-card>
  <app-event-card *ngIf="futureEventInstance" [event]="futureEventInstance.event" [instance]="futureEventInstance" [options]="{show: ['img', 'overview', 'date', 'checkin', 'cost']}"></app-event-card>
  <app-spirit-card *ngIf="futureTs" [spirit]="futureTs.spirit" [ts]="futureTs" [tsSpoiler]="true" [tree]="futureTs.tree" [options]="{homeBackground: true, show: ['img', 'ts', 'cost']}">
    <div title><h2 class="h3 mb-0">Traveling Spirit <span class="c-accent h4">(#{{futureTs.number}})</span></h2></div>
  </app-spirit-card>
  <app-returning-spirit-card *ngIf="futureRs" [return]="futureRs"></app-returning-spirit-card>
</div>

