
<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">Wing Buffs</h1>
  </div>
  <div class="sky-card-body">
    <app-wiki-link [wiki]="{href: 'https://sky-children-of-the-light.fandom.com/wiki/Wing_Buffs'}"></app-wiki-link>
  </div>
</div>

<!-- -->
<div class="sky-card mt">
  <div class="sky-card-header">
    <div>
      <span *ngIf="type" class="c-accent"> ({{ type | spiritType }})</span>
      You have unlocked
      <b [class.completed]="unlocked === total">{{ unlocked }}</b>
      out of
      <b [class.completed]="unlocked === total">{{ total }}</b>
      wing buffs.
    </div>
  </div>
  <div class="sky-card-body scroll-x">
    <app-table [rows]="rows">
      <ng-template [appTableHeader]>Spirit</ng-template>
      <ng-template [appTableHeader]>Type</ng-template>
      <ng-template [appTableHeader]>Origin</ng-template>
      <ng-template [appTableHeader]>Unlocked</ng-template>

      <ng-template [appTableColumn] let-row="row">
        <a [routerLink]="['/spirit', row.guid]">
          {{ row.name }}
        </a>
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        <app-spirit-type-icon [type]="row.type"></app-spirit-type-icon>
      </ng-template>
      <ng-template [appTableColumn] let-row="row">
        <a *ngIf="row.season" [routerLink]="['/season', row.season.guid]">{{ row.season.name }}</a>
        <a *ngIf="row.type === 'Regular'" [routerLink]="['/realm', row.area.realm.guid]">{{ row.area.realm.name }}</a>
      </ng-template>

      <ng-template [appTableColumn] let-row="row">
        <span [class.completed]="row.unlocked === row.total">
          {{ row.unlocked }} / {{ row.total }}
        </span>
      </ng-template>

      <!-- Footers -->
      <ng-template [appTableFooter] [colspan]="3" [textAlign]="'right'">Total:</ng-template>
      <ng-template [appTableFooter]>
        <span [class.completed]="unlocked === total">{{ unlocked }} / {{ total }}</span>
      </ng-template>
    </app-table>
  </div>
</div>

<div class="mt">

</div>
