<aside class="menu-toggle" *ngIf="folded" (click)="foldMenu()" [ngbTooltip]="'Open menu'" container="body" placement="right">
  <mat-icon>keyboard_double_arrow_right</mat-icon>
</aside>
<aside class="menu" [class.folded]="folded">
  <nav>
    <div class="items">
      <!-- Fold menu-->
      <a class="item menu-fold" (click)="foldMenu()" [ngbTooltip]="wide ? '' : 'Close menu'" placement="right" container="body">
        <mat-icon>keyboard_double_arrow_left</mat-icon>
        <span class="name">Close menu</span>
      </a>
      <hr/>
      <!-- Home-->
      <a class="item" href="/" (click)="gotoHome($event)" [ngbTooltip]="wide ? '' : 'Home'" placement="right" container="body">
        <mat-icon>home</mat-icon>
        <span class="name header">Sky Planner</span>
      </a>
      <hr/>
      <!-- Currency -->
      <a class="item" [routerLink]="'currency'" routerLinkActive="menu-active" [ngbTooltip]="wide ? '' : 'Currency'" placement="right" container="body">
        <app-icon [src]="'#candle'" [width]="'24px'" [height]="'24px'" [verticalAlign]="'top'"></app-icon>
        <span class="name">Currency</span>
      </a>
      <!-- Items -->
      <a class="item" [routerLink]="'item'" routerLinkActive="menu-active" [ngbTooltip]="wide ? '' : 'Items'" placement="right" container="body">
        <mat-icon>checkroom</mat-icon>
        <span class="name">Items</span>
      </a>
      <!-- Spirits -->
      <a class="item" [routerLink]="'spirits'" routerLinkActive="menu-active" [ngbTooltip]="wide ? '' : 'Spirits'" placement="right" container="body">
        <mat-icon>person</mat-icon>
        <span class="name">Spirits</span>
      </a>
      <!-- Winged Light -->
      <a class="item" [routerLink]="'winged-light'" routerLinkActive="menu-active" [ngbTooltip]="wide ? '' : 'Winged Light'" placement="right" container="body">
        <mat-icon svgIcon="flaps"></mat-icon>
        <span class="name">Winged Light</span>
      </a>
      <!-- Realms -->
      <a class="item" [routerLink]="'realm'" routerLinkActive="menu-active" [ngbTooltip]="wide ? '' : 'Realms'" placement="right" container="body">
        <mat-icon>map</mat-icon>
        <span class="name">Realms</span>
      </a>
      <!-- Seasons -->
      <a class="item" [routerLink]="'season'" routerLinkActive="menu-active" [ngbTooltip]="wide ? '' : 'Seasons'" placement="right" container="body">
        <img *ngIf="seasonIconUrl; else seasonIcon" [src]="seasonIconUrl" class="icon">
        <ng-template #seasonIcon><mat-icon>ac_unit</mat-icon></ng-template>
        <span class="name">Seasons</span>
      </a>
      <!-- Events -->
      <a class="item" [routerLink]="'event'" routerLinkActive="menu-active" [ngbTooltip]="wide ? '' : 'Events'" placement="right" container="body">
        <mat-icon>celebration</mat-icon>
        <span class="name">Events</span>
      </a>
      <!-- Shop -->
      <a class="item" [routerLink]="'shop'" routerLinkActive="menu-active" [ngbTooltip]="wide ? '' : 'Shop'" placement="right" container="body">
        <mat-icon>shopping_cart</mat-icon>
        <span class="name">Shops</span>
      </a>
      <!-- Tools -->
      <a class="item" [routerLink]="'tools'" routerLinkActive="menu-active" [ngbTooltip]="wide ? '' : 'Tools'" placement="right" container="body">
        <mat-icon>build</mat-icon>
        <span class="name">Tools</span>
      </a>

      <hr class="bottom"/>
      <!-- Settings -->
      <a class="item" [routerLink]="'settings'" routerLinkActive="menu-active" [ngbTooltip]="wide ? '' : 'Settings'" placement="right" container="body">
        <mat-icon>settings</mat-icon>
        <span class="name">Settings</span>
      </a>
      <!-- Credits -->
      <a class="item" [routerLink]="'credits'" routerLinkActive="menu-active" [ngbTooltip]="wide ? '' : 'Credits'" placement="right" container="body">
        <mat-icon>info</mat-icon>
        <span class="name">Info</span>
      </a>
    </div>
  </nav>
</aside>
<div class="aside-tail"></div>
