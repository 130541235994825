<div class="mt">
  <app-card [foldable]="true" [folded]="!hasOngoingItems" [title]="'Wishlist'">
    @if (hasOngoingItems) {
      <div class="container">
        Limited items you have favourited are available right now! This section keeps track of ongoing seasons, events, traveling spirits and special visits.
      </div>
      <div class="sky-flex flex-wrap mt">
        @for (item of items; track item.guid) {
          @if (ongoingItems[item.guid]) {
            <a class="wishlist-item" [ngbTooltip]="item.name" container="body" [routerLink]="itemLinks[item.guid]?.route" [queryParams]="itemLinks[item.guid]?.extras?.queryParams">
              <app-item-icon [item]="item" [opaque]="true" [hoverGlow]="false" [subIcons]="['type', 'elder','season', 'iap', 'unlock']"></app-item-icon>
            </a>
          }
        }
      </div>
      <div class="container wishlist-cost mt">
        Total cost: <app-cost [cost]="itemCost" [showZeroes]="true" [price]="iapPrice"></app-cost>
      </div>
    } @else {
      <div class="container">
        Limited items you have favourited will appear here when they are available. This section keeps track of ongoing seasons, events, traveling spirits and special visits.
      </div>}
  </app-card>
</div>
