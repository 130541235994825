<div class="node" [ngbTooltip]="itemHover" placement="bottom" container="body">
  <div class="item" (click)="nodeClick($event)" [class.highlight]="highlight">
    <app-item-icon [item]="node.item" [opaque]="opaque" [listNode]="node" [subIcons]="['unlock']"></app-item-icon>
  </div>
  <div class="cost">
    @if (!cost) {
      <span class="free">Free</span>
    } @else {
      <span>{{ cost }}</span>
      <mat-icon [svgIcon]="currencyIcon" [class]="currencyIconClass"></mat-icon>
    }
  </div>
  @if (nodeOverlayTemplate) { <ng-container [ngTemplateOutlet]="nodeOverlayTemplate" [ngTemplateOutletContext]="{ $implicit: node }"></ng-container> }
</div>

<ng-template #itemHover>
  <ng-container *ngIf="node.item">
    <span class="ws-nw">{{node.item.name}}</span>
  </ng-container>
</ng-template>
