<div *ngIf="item" class="item-info">
  <div class="sky-card" *ngIf="item">
    <div class="sky-card-header">
      <h1 class="h2">{{ item.name }}</h1>
    </div>
    <div class="sky-card-body">
      <div class="info-grid" [class.has-preview]="item.previewUrl">
        <!-- Preview -->
        <div class="info-grid-preview" *ngIf="item.previewUrl">
          <img [src]="item.previewUrl" class="preview-img" loading="lazy">
          <app-item-icon [item]="item" [opaque]="true" [hoverGlow]="false"></app-item-icon>
          <app-item-subicons [item]="item" [showTooltips]="true" [icons]="[ 'type', 'elder', 'season', 'iap', 'favourite', 'unlock']"></app-item-subicons>
        </div>
        <!-- Icon -->
        <div class="info-grid-icon" *ngIf="!item.previewUrl">
          <app-item-icon [item]="item" [size]="'large'" [opaque]="true" [hoverGlow]="false"></app-item-icon>
          <app-item-subicons [item]="item" [showTooltips]="true" [icons]="[ 'type', 'elder', 'season', 'iap', 'favourite', 'unlock']"></app-item-subicons>
        </div>
        <!-- Wiki -->
        <app-wiki-link *ngIf="item._wiki?.href" [wiki]="item._wiki" [aClass]="'container'"></app-wiki-link>
        <!-- Favourite -->
        <div class="container point" (click)="toggleFavourite(item)">
          <mat-icon class="menu-icon item-favourite" [class.favourited]="item.favourited">star</mat-icon>
          <span class="menu-label">{{ item.favourited ? 'Unfavourite' : 'Favourite' }}</span>
        </div>
        <!-- Find -->
        <a *ngIf="navSource" class="container" [routerLink]="navSource.route" [queryParams]="navSource.extras?.queryParams">
          <mat-icon class="menu-icon">search</mat-icon>
          <span class="menu-label">Find item</span>
        </a>
        <!-- List -->
        <a *ngIf="navList" class="container" [routerLink]="navList.route" [queryParams]="navList.extras?.queryParams">
          <mat-icon class="menu-icon">list</mat-icon>
          <span class="menu-label">Show in item list</span>
        </a>
        <!-- Limited-->
        @if (item.group === 'Limited') {
          <div class="container">
            <mat-icon class="menu-icon">update_disabled</mat-icon>
            <span class="menu-label">This is a limited item; it will likely not return.</span>
          </div>
        } @else if (item.group === 'Ultimate') {
          <div class="container">
            <mat-icon class="menu-icon">update_disabled</mat-icon>
            <span class="menu-label">This is a season ultimate; it will likely not return.</span>
          </div>
        }
      </div>
    </div>
  </div>
</div>
