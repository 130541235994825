import { Component } from '@angular/core';

@Component({
    selector: 'app-no-data',
    templateUrl: './no-data.component.html',
    styleUrl: './no-data.component.less',
    standalone: true
})
export class NoDataComponent {

}
