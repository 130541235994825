<div class="table-container">
  <table class="">
    <thead *ngIf="headers?.length">
      <tr>
        <ng-container *ngFor="let header of headers;">
          <th [class.fit]="header.fit" [style.textAlign]="header.textAlign" [style.width]="header.width">
            <ng-container [ngTemplateOutlet]="header.template"></ng-container>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let row of rows">
        <tr>
          <ng-container *ngFor="let column of columns; let ci = index;">
            <td [class]="column.tdClass">
              <ng-container [ngTemplateOutlet]="column.template" [ngTemplateOutletContext]="{row}"></ng-container>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
    <tfoot *ngIf="footers?.length">
      <tr>
        <ng-container *ngFor="let footer of footers">
          <td [attr.colspan]="footer.colspan" [style.text-align]="footer.textAlign">
            <ng-container [ngTemplateOutlet]="footer.template"></ng-container>
          </td>
        </ng-container>
      </tr>
    </tfoot>
  </table>
</div>
