<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">Harmony Hall</h1>
  </div>
  <div class="sky-card-body">
    <app-wiki-link [wiki]="{href: 'https://sky-children-of-the-light.fandom.com/wiki/Harmony_Hall'}"></app-wiki-link>
  </div>
</div>

<!-- Spirit trees -->
<div class="mt">
  <app-card [foldable]="true" [folded]="false" [title]="'Spirit trees'">
    <div class="sky-flex scroll-x">
      <app-spirit-tree [tree]="tree!" [name]="'Harmony Hall Guide'"></app-spirit-tree>
    </div>
  </app-card>
</div>

<!-- IGC -->
@if (igcShops.length) {
  <div class="mt">
    <app-card [foldable]="true" [folded]="false" [title]="'In-game currency'">
      <div class="sky-flex flex-wrap">
        @for (shop of igcShops; track shop.guid) {
          <div class="sky-card">
            <div class="sky-card-header">
              <h3 class="h4 mb-0">
                <span>{{ shop.name }}</span>
              </h3>
            </div>
            <div class="sky-card-body">
              <app-item-list [itemList]="shop.itemList!" [highlightNode]="highlightNode"></app-item-list>
            </div>
          </div>
        }
      </div>
    </app-card>
  </div>
}

<!-- IAPs -->
@if (iapShops.length) {
  <div class="mt">
    <app-card [foldable]="true" [folded]="false" [title]="'In-app purchases'">
      <div class="grid grid-4">
        @for (shop of iapShops; track shop.guid) {
          @for (iap of shop.iaps; track iap.guid) {
            <app-iap-card [iap]="iap" [highlightIap]="highlightIap"></app-iap-card>
          }
        }
      </div>
    </app-card>
  </div>
}

